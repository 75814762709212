<template>
    <v-row justify="center">
    <v-dialog :value="showDialog" scrollable max-width="90%" persistent>
      <v-card height="288vh">
         <v-card-title class="" style="position: fixed; top: 7%; right: 10%;">
              <v-icon @click="(e) => closeDialog(e)">mdi-close-circle</v-icon>
            </v-card-title>

          <v-container style="font-size: 14px">

            <v-row no-gutters justify="space-between" style="border-bottom: 1px solid black">
              <v-col cols="10">
              <v-card-text>
                <div>
                  <span class="" style="font-weight: 800; font-size: 16px">
                    <strong>「タカラバイオ株式会社　HEALGEN COVID-19 抗原迅速テスト (一般用)」販売チェック</strong>
                  </span>
                </div>
              </v-card-text>
            </v-col>
            </v-row>
            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ 抗原検査キット（OTC）は、家庭等において、体調が気になる場合等にセルフチェックとして使用するものであり、使用者自身で新型コロナウイルス感染症の診断を行うことはできません。
              </p>
              <p class="pt-2">そのため以下の点にご注意ください。</p>
              <div class="ml-4">
                <p class="pt-3">
                  ・<strong>偽陰性の可能性があること</strong>
                  （感染していてもウイルス量が少ない場合には、結果が陰性となる場合があります。）
                </p>
                <p class="pt-2">
                  ・<strong>陰性証明として用いることはできないこと</strong>
                  （無症状者に対する確定診断には使用できず、陰性であったとしても引き続き感染予防策を講じる必要があります。）
                </p>
              </div>
              <v-checkbox
              v-model="checkbox_1"
              class="mb-3 ml-6"
              label="確認しました"
              ></v-checkbox>
            </div>

            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ <strong>下記リンクより、検査の実施方法等についてしっかりご理解ください。</strong>
              </p>
              <div class="ml-4">
                <p class="pt-3">
                  ・<a href="https://www.takara-bio.co.jp/medical/pdfs/HEALGEN_COVID-19_otc_users_guide.pdf" target="_new" rel="noopener">
                  HEALGEN COVID-19 抗原迅速テスト (一般用)　使用者向け使用の手引き (takara-bio.co.jp)
                  </a>
                </p>
                <p class="pt-2">
                  ・<a href="https://www.takara-bio.co.jp/medical/pcr_kit4.htm#healgen_otc_video" target="_new" rel="noopener">
                  HEALGEN COVID-19 抗原迅速テスト (一般用)　操作ビデオ (takara-bio.co.jp)
                  </a>
                </p>
                <p class="pt-3">
                  実施方法に不明な点がある場合や、リンク先が見られない場合は、トーカイ薬局（0572-24-4925）までお問い合わせください。
                </p>
              </div>
              <v-checkbox
              v-model="checkbox_2"
                class="mb-3 ml-6"
                label="確認しました"
              ></v-checkbox>
            </div>

            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ <strong>検査後の対応について</strong>
              </p>
              <div class="ml-4">
                <p class="pt-3">
                  ・陽性の判定の場合には、かかりつけ医や検査医療機関への受診か、岐阜県陽性者登録センターへの登録を行うこと。
                </p>
                <p class="pt-2">
                  <a href="https://www.pref.gifu.lg.jp/site/covid19/25920.html" target="_new" rel="noopener">
                    発熱等の症状がある場合の相談・受診方法 - 岐阜県公式ホームページ（感染症対策推進課） (gifu.lg.jp)
                  </a>
                </p>
                <p class="pt-2">
                  <a href="https://www.pref.gifu.lg.jp/site/covid19/240567.html" target="_new" rel="noopener">
                    岐阜県陽性者登録センター - 岐阜県公式ホームページ（感染症対策推進課） (gifu.lg.jp)
                  </a>
                </p>
                <p class="pt-3">
                  ・陰性の判定の場合でも、偽陰性の可能性があることも考慮し、症状がない場合 であっても、引き続き、外出時のマスク着用、手指消毒等の基本的な感染対策を続けること。症状がある場合には、上記リンク先を参考に受診等を行うこと。
                </p>
              </div>
              <v-checkbox
              v-model="checkbox_3"
                class="mb-3 ml-6"
                label="確認しました"
              ></v-checkbox>
            </div>

            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ <strong>他人への譲渡、販売、転売はしないでください。</strong>
              </p>
              <div class="ml-4">
                <p class="pt-3">
                  販売に当たり、購入した抗原検査キット（OTC）の全部又は一部を他者に販売又は授与する行為（転売）は、薬機法第 24 条第１項に違反するおそれがあります。
                </p>
              </div>
              <v-checkbox
              v-model="checkbox_4"
                class="mb-3 ml-6"
                label="確認しました"
              ></v-checkbox>
            </div>

            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ <strong>こちらの商品は「薬剤師による適正使用の確認」が完了するまではご注文確定となりませんので、通常商品よりもお届けが遅くなる場合がございます。</strong>
              </p>
              <div class="ml-4">
                <p class="pt-3">
                  【当日配送をご希望の方】
                  トーカイ薬局オンラインショップ営業日の12時までにご注文をお願いします。<br>
                  ご注文後、トーカイ薬局からご登録いただきましたメールアドレスまたは電場番号へご連絡させていただきます。<br>
                  13時までに適正使用の確認が完了した方のみ当日配送が可能となります。<br>
                </p>
                <p class="pt-3">
                  ※ こちらの商品は第1類医薬品です。薬剤師による適正使用の確認後に発送されます。
                </p>
                <p class="pt-2">
                  ※ ご購入にあたりお客様の状態確認の為の質問にお答えいただく必要があります。
                </p>
                <p class="pt-2">
                  ※ 薬剤師による「適正使用の確認」の結果によってはご注文をキャンセルさせていただく場合がございますのでご注意ください。
                </p>
              </div>
              <v-checkbox
              v-model="checkbox_5"
                class="mb-3 ml-6"
                label="確認しました"
              ></v-checkbox>
            </div>

            <div class="mt-4" style="border-bottom: 1px solid black">
              <p>
                ■ <strong>他にこの医薬品について、 薬剤師に質問・伝達事項などがあればご記入ください。</strong>
              </p>
              <v-textarea
              v-model="notes"
              class="mb-2 mt-2"
              height="80px"
              outlined
              ></v-textarea>
            </div>
          </v-container>
        <v-card-actions>
          <v-container class="d-flex justify-center">
            <v-row justify="center" align="center">
              <v-col cols="12" md="6" sm="6" class="d-flex justify-center">
                <v-btn
                  depressed
                  small
                  :disabled="
                    data_product.is_stock == 0 ||
                    data_product.item_status < 0 ||
                    data_product.stock == 0 ||
                    data_product.stock_total == 0 ||
                    checkbox_1 == false || checkbox_2 == false || checkbox_3 == false || checkbox_4 == false || checkbox_5 == false
                  "
                  block
                  style="padding: 0px"
                  color="#ff0090"

                  @click="(e) => addCart(e, data_product)"
                >
                  <v-icon
                    v-if="data_product.stock !== 0"
                    class="pe-1"
                    color="white"
                    size="20"
                    small
                  >
                    mdi-cart-outline
                  </v-icon>
                  <div v-if="data_product.stock !== 0" style="color: white">
                    {{ $t("message.btn-add-cart") }}
                  </div>
                  <div v-else-if="data_product.stock == 0" style="color: white">
                    売り切れ
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  depressed
                  small
                  color="secondary"
                  block
                  @click="(e) => closeDialog(e)"
                >{{ $t("message.back") }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    name: "ConfirmationProduct",
    data() {
        return {
            message: {},
            itemId: "",
            notes: "",
            checkbox_1: false,
            checkbox_2: false,
            checkbox_3: false,
            checkbox_4: false,
            checkbox_5: false,
            clicked: false
        }
    },
    props: {
        showDialog: Boolean,
        data_product: Object
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
            this.checkbox_1 = false;
            this.checkbox_2 = false;
            this.checkbox_3 = false;
            this.checkbox_4 = false;
            this.checkbox_5 = false;
        },
        addCart(e, data) {
          this.clicked = true
          if(this.checkbox_1 && this.checkbox_2 && this.checkbox_3 && this.checkbox_4 && this.checkbox_5 && this.clicked) {

            let temp = "";
            let setNotes = this.notes;
            if(setNotes !== "") {
              temp = setNotes+'\n';
            }
            localStorage.setItem("notes", temp)
            this.notes = ""
            setTimeout(() => {
              this.closeDialog()
            }, 1800)

              if(data.item_id) {
                  this.itemId = data.item_id

              } else {
                  this.itemId = data.id;
              }

              e.stopPropagation();
                  let cookies = $cookies.get("isLogin");
                  if (cookies === 0) {
                      Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: this.$t("message.title-err-login-first"),
                      });
                  } else {
                      if (data.is_alcohol_item == 1) {
                          // this.dialog = true;
                      } else {
                          this.message.title = this.$t("message.title-add-carts");
                          this.$store.dispatch("carts_module/addCarts", {
                          itemId: this.itemId,
                          storeId: data.store_id,
                          message: {
                              title: this.message.title,
                          },
                          });
                          this.itemId = ""
                      }
                  }

          } else {
            return
          }

        },
    },
}
</script>

<style scoped>
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}
.v-input__slot {
  width: 50%;
}
.v-input--selection-controls {
  width: 50%;
}
</style>