<template>
  <div style="">
    <div v-if="data_slide.length > 0">
      <Section
        :title_name="title_slide"
        :link="link_slide"
        :has_show="has_show_section"
      />
    </div>

    <v-container>
      <div v-if="data_slide.length > 0">
        <v-row
          style="overflow-x: auto; flex-wrap: nowrap; padding-right: 0px"
          class="row-scroll"
        >
          <v-col
            v-for="(list, idx) in data_slide"
            cols="4"
            md="3"
            lg="2"
            sm="3"
            :key="idx"
            class="parent-style-product"
            style="padding-right: 0px"
          >
            <div>
              <Product
                @data-slug="getModal"
                :data="list"
                :loading="is_loading"
                :slug_category="slug_category"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <DetailModal
      :item_slug="slug"
      :showDialog="dialog"
      @get-modal="handleModal"
      @dialogVisible="dialog"
    />

    <ConfirmationProduct
      :data_product="data_dialog_confirm"
      :showDialog="dialog_confirmation_product"
      @close="getCloseModal"
      @dialogVisible="dialog_confirmation_product"
    />

    <Warning
      :openDialog="dialog_warning"
      @handle-dialog="handleDialog_warning"
    />
  </div>
</template>

<script>
import Warning from "../components/Warning.vue";
import Product from "@/components/Product";
import Skeleton from "@/components/skeleton/Skeleton";
import LoadingPage from "@/components/LoadingPage";
import StoreList from "./StoreList.vue";
import Section from "./Section.vue";
import { mdiChevronRight } from "@mdi/js";
import DetailModal from "../components/DetailModal.vue";
import ConfirmationProduct from "../components/ConfirmationProduct.vue";
export default {
  name: "ProductSlide",
  data() {
    return {
      iconViewAll: mdiChevronRight,
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
      message: {},
    };
  },
  methods: {
    handleDialog_warning(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.data_dialog_warning.id,
          storeId: this.data_dialog_warning.store_id,
          message: {
            title: this.message.title,
          },
        });
        setTimeout(() => {
          this.dialog = false;
        }, 1800);
      }
      this.dialog_warning = false;
    },
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else if (data[0] == "dialog_alcohol") {
        this.data_dialog_warning = data[1];
        this.dialog_warning = true;
      } else {
        this.dialog = false;
      }
    },
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
  },
  components: {
    Product,
    Skeleton,
    LoadingPage,
    StoreList,
    Section,
    DetailModal,
    ConfirmationProduct,
    Warning,
  },
  props: {
    data_store: Array,
    title_store: String,
    title_slide: String,
    link_slide: String,
    errorMessageFavs: Object,
    is_loading: Boolean,
    data_slide: Array,
    label_slide: String,
    sub_name_section: String,
    has_show_section: Boolean,
    slug_category: String,
  },
  created() {},
};
</script>
<style>
.parent-style-product {
  width: 60%;
  padding: 4px;
}
.row-scroll {
  /* height: 150vh; */
}
.row-scroll::-webkit-scrollbar {
  display: none;
}
</style>
