<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" scrollable max-width="90%" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon @click="(e) => getModal(e)">{{ svgPath }}</v-icon>
        </v-card-title>
        <div v-if="isLoading" class="text-center mb-5">
          <SkeletonDetail />
        </div>
        <v-card-text v-else>
          <v-container style="margin-bottom: 1em">
            <!-- <div class="d-flex flex-row ps-0">
              <div class="ps-0">
                <v-breadcrumbs
                  :items="items"
                  large
                  class="pt-0 ps-0 breadcrumb"
                ></v-breadcrumbs>
              </div>
            </div> -->
            <div class="text-product">
              {{ itemData.item_name }}
            </div>
            <div
              class="text-merchant pb-1"
              @click="gotoShop(itemData.store_id)"
              style="cursor: pointer"
            >
              <v-icon size="20"> mdi-store </v-icon>
              <span>{{ storeName }}</span>
            </div>
            <div class="mb-2">
              <!-- <p style="font-size: 9px">
                {{ $t("message.term-cond-store") }}.
                <span
                  class="pink--text"
                  @click.prevent="shopInfo(shopInfo_link.info_shop_url)"
                >
                  {{ $t("message.click") }}
                </span>
              </p> -->
              <p v-if="shopInfo_link.info_medic_url" style="font-size: 9px">
                医薬品販売について
                <span
                  class="pink--text"
                  style="cursor: pointer"
                  @click.prevent="
                    $router.push(`/store/${itemData.store_id}/medic`)
                  "
                >
                  {{ $t("message.click") }}
                </span>
              </p>
            </div>
            <div v-if="itemData.is_alcohol_item == 1" class="mx-4 my-4">
              <v-img
                :lazy-src="warning"
                max-height="150"
                :src="warning"
              ></v-img>
            </div>
            <v-row>
              <v-col>
                <v-carousel
                  v-model="model"
                  height="343"
                  contain
                  transition="fade-transition"
                  reverse-transition="fade-transition"
                  :hide-delimiter-background="true"
                  :hide-delimiters="slide.length > 1 ? false : true"
                  style="position: relative"
                  :show-arrows="slide.length > 1 ? true : false"
                  :cycle="false"
                >
                  <v-carousel-item
                    v-for="(slide_item, i) in slide"
                    :key="i"
                    contain
                    :src="slide_item"
                  >
                  </v-carousel-item>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="white"
                    style="position: absolute; bottom: 10%; left: 2%"
                    @click="addFavorite(itemData)"
                  >
                    <v-icon dark :color="isFavorite()"> mdi-heart </v-icon>
                  </v-btn>
                </v-carousel>
                <div
                  class="text-price d-flex justify-start"
                  style="color: #ff0090"
                >
                  <span style="font-size: 18px">
                    <span
                      style="font-size:16px;vertical-align: baseline;16px;margin-right: 10px;"
                    >
                      {{ $t("message.without-tax") }}
                    </span>
                    <span style="font-size: 18px">
                      {{ formatDecWithoutComa(itemData.price_without_tax) }}
                    </span>
                    <span
                      style="
                        font-size: 16px;
                        vertical-align: baseline;
                        margin-right: 10px;
                      "
                    >
                      円</span
                    >
                  </span>
                </div>
                <div
                  v-if="!conditionalPrice(itemData.price)"
                  class="grey--text d-flex justify-start"
                >
                  <div class="">
                    <span style="margin-right: 10px">
                      {{ $t("message.with-tax") }}
                    </span>
                    <span style="font-size: 18px">
                      {{ formatDecWithoutComa(itemData.price) }} 円
                    </span>
                  </div>
                </div>
                <div v-else class="grey--text d-flex justify-start">
                  <span style="margin-right: 10px">
                    {{ $t("message.with-tax") }}
                  </span>
                  <span style="font-size: 18px">
                    {{ priceBeforeComa(itemData.price) }}
                    <span style="font-size: 12px; margin-left: -3px">
                      {{ priceAfterComa(itemData.price) }} 円
                    </span>
                  </span>
                </div>

                <!-- <v-divider style="color: blue"></v-divider> -->

                <div class="mb-3 mt-5">
                  <div style="font-size: 16px; font-weight: 500">
                    {{ $t("message.label-product-desc") }}
                  </div>
                  <div class="desc-class">
                    {{ itemData.description }}
                  </div>
                </div>

                <div class="mb-3 mt-5">
                  <div class="" style="font-size: 16px; font-weight: 500">
                    {{ $t("message.composition") }}
                  </div>
                  <div
                    v-html="specification"
                    class="desc-class"
                    style="white-space: pre-wrap"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="!isLoading">
          <v-container class="d-flex justify-center">
            <v-row justify="center" align="center">
              <v-col cols="12" md="6" sm="6" class="d-flex justify-center">
                <v-btn
                  depressed
                  small
                  :disabled="
                    itemData.is_stock == 0 ||
                    itemData.item_status < 0 ||
                    itemData.stock == 0 ||
                    itemData.stock_total == 0
                  "
                  block
                  style="padding: 0px"
                  color="#ff0090"
                  @click="(e) => addCart(e, itemData)"
                >
                  <v-icon
                    v-if="itemData.stock !== 0"
                    class="pe-1"
                    color="white"
                    size="20"
                    small
                  >
                    mdi-cart-outline
                  </v-icon>
                  <div v-if="itemData.stock !== 0" style="color: white">
                    {{ $t("message.btn-add-cart") }}
                  </div>
                  <div v-else-if="itemData.stock == 0" style="color: white">
                    売り切れ
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>

      <!-- <Warning :openDialog="dialog_warning" @handle-dialog="handleDialog" /> -->
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiCloseCircle as close } from "@mdi/js";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";
import priceBeforeComa from "../helpers/priceBeforeComa";
import priceAfterComa from "../helpers/priceAfterComa";
import ProductSlide from "../components/ProductSlide.vue";
import SkeletonDetail from "../components/skeleton/SkeletonDetail.vue";
import Warning from "./Warning.vue";

export default {
  name: "DetailModal",
  data() {
    return {
      svgPath: close,
      slug_product: this.item_slug,
      model: 0,
      categoryName: "",
      categorySlug: "",
      parent_name: "",
      parent_slug: "",
      specification: "",
      isLoading: false,
      message: {},
      warning: require("../assets/stop20.png"),
      dialog_warning: false,
    };
  },
  watch: {
    showDialog: {
      deep: true,
      handler(dialog) {
        if (dialog) {
          this.itemDetail();
        }
      },
    },
  },
  components: {
    ProductSlide,
    SkeletonDetail,
    Warning,
  },
  props: {
    item_slug: String,
    showDialog: Boolean,
    marketplace_id: Number,
    group_marketplace_id: Number,
  },
  methods: {
    getModal(e, data) {
      let temp = [];
      if (e == "ConfirmProduct") {
        temp.push(e, data);
        this.$emit("get-modal", temp);
        this.$emit("dialog", true);
      } else if (e == "dialog_alcohol") {
        temp.push(e, data);
        this.$emit("get-modal", temp);
      } else {
        this.$emit("get-modal", false);
      }
    },
    handleDialog(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.itemId,
          storeId: this.storeId,
          message: {
            title: this.message.title,
          },
        });
      }
      this.dialog = false;
    },
    addCart(e, data) {
      let nameEvent;
      if (e.isTrusted) {
        nameEvent = "ConfirmProduct";
      }
      if (data.is_rapid_antigen_tools == 1) {
        this.getModal(nameEvent, data);
      } else {
        this.itemId = data.id;
        this.storeId = data.store_id;
        e.stopPropagation();
        let cookies = $cookies.get("isLogin");
        if (cookies === 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: this.$t("message.title-err-login-first"),
          });
        } else {
          if (data.is_alcohol_item == 1) {
            // this.dialog_warning = true;
            this.getModal("dialog_alcohol", data);
          } else {
            this.message.title = this.$t("message.title-add-carts");
            this.$store.dispatch("carts_module/addCarts", {
              itemId: data.id,
              storeId: data.store_id,
              message: {
                title: this.message.title,
              },
            });
          }
        }
      }
    },
    addFavorite: function (data) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("wishlist-item-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price,
        });

        // let itemId = data.id;
        // let slug = data.slug;
        // let payload = { item_id: itemId, slug: slug };

        let ctg_slug = "";
        let storeId = "";

        if (this.$router.history.current.path.substring(0, 7) == "/store/") {
          let _path = this.$router.history.current.path.split("/");
          storeId = _path[2];
        } else {
          let _ctg_slug = this.$router.history.current.path.split("/");
          if (_ctg_slug[3] == "c") {
            ctg_slug = _ctg_slug[4];
          } else {
            ctg_slug = _ctg_slug[3];
          }
        }

        let payload = {
          item_id: data.id,
          slug: data.slug,
          page: "modal",
          parent_page: this.$router.history.current.path,
          marketplaceId: this.marketplace_id,
          groupMarketplaceId: this.group_marketplace_id,
          ctg_slug: ctg_slug,
          store_id: storeId,
        };
        this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);
      }
    },
    isFavorite() {
      if (this.itemData.has_favourited) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
    gotoShop(id) {
      this.$router.push(`/store/${id}`);
    },
    async itemDetail() {
      let slug = this.item_slug;
      if (slug !== "") {
        this.isLoading = true;
        let data = await this.$store.dispatch(
          "itemDetail_module/itemDetail",
          slug
        );
        this.isLoading = false;
        return data;
      }
    },
    formatDecWithoutComa(price) {
      return formatDecWithoutComa(price);
    },
    conditionalPrice(price) {
      let priceNum = String(price);
      let flag = false;
      for (let i = 0; i < priceNum.length; i++) {
        if (priceNum[i] === ".") {
          flag = true;
          break;
        }
      }
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    priceBeforeComa(price) {
      return priceBeforeComa(String(price));
    },
    priceAfterComa(price) {
      return priceAfterComa(String(price));
    },
  },
  computed: {
    items() {
      if (this.itemData.category_parent_slug == null) {
        return [
          {
            text: this.$t("message.title-home"),
            disabled: false,
            href: `${process.env.BASE_URL}`,
          },
          {
            text: this.$t("message.title-allCategory"),
            disabled: false,
            href: `${process.env.BASE_URL}category-top`,
          },
          {
            text: this.categoryName,
            disabled: false,
            href: `${process.env.BASE_URL}items/category/c/${this.itemData.category_slug}`,
          },
        ];
      } else {
        return [
          {
            text: this.$t("message.title-home"),
            disabled: false,
            href: `${process.env.BASE_URL}`,
          },
          {
            text: this.$t("message.title-allCategory"),
            disabled: false,
            href: `${process.env.BASE_URL}category-top`,
          },
          {
            text: this.parent_name,
            disabled: false,
            href: `${process.env.BASE_URL}items/category/${this.itemData.category_parent_slug}`,
          },
          {
            text: this.categoryName,
            disabled: false,
            href: `${process.env.BASE_URL}items/category/c/${this.itemData.category_slug}`,
          },
        ];
      }
    },
    similiarProduct() {
      return this.$t("message.label-similar-product");
    },
    storeName() {
      let name =
        this.$store.state.itemDetail_module.itemStoreResult.toLowerCase();
      let splitName = name.split(" ").join("");
      // let join = splitName.join('')
      return this.$store.state.itemDetail_module.itemStoreResult;
    },
    shopInfo_link() {
      return this.$store.state.itemDetail_module.shopInfo_link;
    },
    slide() {
      if (this.$store.state.itemDetail_module.itemImageResult.length == 0) {
        return [this.notFound];
      }
      return this.$store.state.itemDetail_module.itemImageResult;
    },
    similarProduct() {
      return this.$store.state.itemDetail_module.similarProduct;
    },
    itemData() {
      this.categoryName =
        this.$store.state.itemDetail_module.itemDetailResult.category_name;
      this.categorySlug =
        this.$store.state.itemDetail_module.itemDetailResult.category_slug;
      // this.slug =
      //   this.$store.state.itemDetail_module.itemDetailResult.item_name;
      this.parent_name =
        this.$store.state.itemDetail_module.itemDetailResult.category_parent_name;
      this.parent_slug =
        this.$store.state.itemDetail_module.itemDetailResult.category_parent_slug;
      this.specification =
        this.$store.state.itemDetail_module.itemDetailResult.specification;

      return this.$store.state.itemDetail_module.itemDetailResult;
    },
  },
};
</script>
<style>
.desc-class {
  word-break: break-all;
  font-size: 14px;
}
.breadcrumb li a {
  color: grey !important;
  font-size: 12px !important;
}
.v-carousel__controls .v-btn__content i {
  font-size: 12px !important;
}
.v-carousel__controls button span i {
  color: #ff0090 !important;
}

.v-carousel__controls {
  height: 30px !important;
}

.v-carousel__controls .v-item-group button {
  width: 13px;
  height: 13px;
  margin: 0;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  background-color: #ff0090;
  opacity: 1;
}

/* .v-btn--icon.v-size--small {
  width: 2px !important;
  height: 10px !important;
} */

.v-window__prev {
  margin-left: -10px !important;
}

.v-window__next {
  margin-right: -10px !important;
}

.row-header {
  background-color: white;
  height: 36px;
  width: 100%;
  margin-top: -15px;
}
.row-image-detail {
  background-color: gray;
  margin-bottom: 2%;
  max-height: 376px;
}
.text-fee {
  font-size: 18px;
  font-weight: 500;
}
.text-price {
  font-size: 16px;
  font-weight: 500;
  margin-top: 19.6px;
}
.text-product {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
}
.text-merchant {
  font-size: 14px;
  font-weight: 400;
  margin: 3px 3px 3px 0px;
}
</style>
